<template>
  <div class="pageContent">
    <searchHeader />
    <div class="financialAccountReceive">
      <div class="jrjs_banner5"><p @click="scrollToApplication()" class="jr_banner_btn">我要申请</p></div>
      <div class="jr_cp_bg">
        <div class="wrap">
          <div class="jr_cp">
            <p>该产品主要指交易商将存入交易中心指定交收仓库，在交易中心注册仓单后，可申请办理仓单质押融资业务。该产品目前正在开发当中。</p>
          </div>
        </div>
        <div class="jr_lc"></div>
        <div class="applicationForm">
          <div class="jrjs_tit7" id="application"></div>
          <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="申请人姓名" prop="name">
                  <el-input v-model="form.name" placeholder="请输入申请人姓名"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申请人单位" prop="company">
                  <el-input v-model="form.company" placeholder="请输入申请人单位"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话" prop="phone">
                  <el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品类型" prop="type">
                  <el-select class="width-100" v-model="form.type" placeholder="请选择产品类型">
                    <el-option
                      v-for="item in typeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注" prop="note">
                  <el-input type="textarea" v-model="form.note" placeholder="请输入备注"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <input class="rzsq_btn" type="button" value="提交" />
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchHeader from "../../base/header/searchHeader";

export default {
  data() {
    return {
      form: {
        name: null,
        company: null,
        phone: null,
        type: null,
        note: null
      },
      rules: {
        name: [
          { required: true, message: '请输入申请人姓名', trigger: 'blur' },
        ],
        company: [
          { required: true, message: '请输入申请人单位', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择产品类型', trigger: 'blur' },
        ],
        note: [
          { required: true, message: '请输入备注', trigger: 'blur' },
        ]
      },
      typeOptions: [
        {
          value: 1,
          label: '应收账款融资业务'
        },
        {
          value: 2,
          label: '应收账款融资业务'
        },
        {
          value: 3,
          label: '订单融资业务'
        },
        {
          value: 4,
          label: '仓单质押融资业务'
        }
      ]
    }
  },
  components: {
    searchHeader
  },
  methods: {
    scrollToApplication() {
      document.querySelector("#application").scrollIntoView(true);
    }
  }
}
</script>
<style scoped lang="scss">
.jrjs_banner5 {
  background: url('../../../assets/configImages/jr_banner5.jpg') no-repeat center;
  height: 203px;
  padding-top: 300px;
}
.jr_banner_btn {
  display: block;
  width: 221px;
  height: 51px;
  line-height: 51px;
  text-align: center;
  color: #fff;
  font-size: 34px;
  margin: auto;
  background: url('../../../assets/configImages/jr_banner_btn.jpg');
  cursor: pointer;
}
.jr_cp_bg {
  background: #f3f6fb;
  padding: 30px 0;
}
.wrap {
  width: 1300px;
  margin: auto;
  position: relative;
}
.jr_cp {
  padding: 30px 0;
}
.jr_cp p {
  color: #535353;
  font-size: 18px;
  line-height: 34px;
  text-indent: 2em;
}
.jrjs_tit4 {
  background: url('../../../assets/configImages/jr_tit4.png') no-repeat center;
  width: 556px;
  height: 39px;
  margin: 30px auto;
}
.jrjs_tit5 {
  background: url('../../../assets/configImages/jr_tit5.png') no-repeat center;
  width: 556px;
  height: 39px;
  margin: 30px auto;
}
.jrjs_tit6 {
  background: url('../../../assets/configImages/jr_tit6.png') no-repeat center;
  width: 556px;
  height: 39px;
  margin: 30px auto;
}
.cplist {
  width: 810px;
  margin: 10px auto 3px auto;
  overflow: hidden;
}
.cplist li {
  float: left;
  width: 270px;
  text-align: center;
}
.cplist li i {
  display: block;
  width: 94px;
  height: 94px;
  background: url('../../../assets/configImages/jr_icons.png');
  margin: auto;
}
.cplist .jyljl_ico12 i {
  background-position: 0 -274px;
}
.cplist .jyljl_ico13 i {
  background-position: -96px -274px;
}
.cplist .jyljl_ico14 i {
  background-position: -192px -274px;
}
.cplist li span {
  font-size: 18px;
  color: #222;
  line-height: 60px;
}
.jr_lc {
  background: url('../../../assets/configImages/jr_lc.jpg') no-repeat center;
  height: 857px;
}
// 申请表单
.applicationForm {
  width: 1300px;
  margin: auto;
  position: relative;
}
.jrjs_tit7 {
  background: url('../../../assets/configImages/jr_tit7.png') no-repeat center;
  width: 556px;
  height: 39px;
  margin: 30px auto;
}
.rzsq_btn {
  display: block;
  cursor: pointer;
  border: 0;
  width: 260px;
  height: 44px;
  background: #2065cc;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 44px;
  margin: 20px auto;
  border-radius: 3px;
}
</style>